import { api, messengerApi } from "@app/api/api";
import { PaginationFilterDef } from "@app/types/pagination.types";

import { ReportingEndpointsEnum } from "../constants/reporting.endpoints";
import {
  ChassisCheckFilterDef,
  ChassisCheckReportDef,
  ChassisCheckReportRequestDef,
  ChassisCheckReportFilterDef,
  MessageReportFilterDef,
  SessionFilterDef,
  SessionReportFilterDef,
  AuditLogReportFilterDef,
} from "../types/reporting.types";

const getSessionReport = (filter: SessionReportFilterDef) => {
  return api.get(ReportingEndpointsEnum.SESSION, {
    params: filter,
  });
};

const getSessionReportExportData = (filter: SessionFilterDef) => {
  return api
    .get(ReportingEndpointsEnum.SESSION_REPORT_EXPORT, {
      params: filter,
      responseType: "arraybuffer",
    })
    .then(response => response.data);
};

const getMessageReport = (filter: MessageReportFilterDef) => {
  return messengerApi.get(ReportingEndpointsEnum.MESSAGE, {
    params: filter,
  });
};

const getMessageReportExportData = (filter: SessionFilterDef) => {
  return messengerApi
    .get(ReportingEndpointsEnum.MESSAGE_REPORT_EXPORT, {
      params: filter,
      responseType: "arraybuffer",
    })
    .then(response => response.data);
};

const getChassisCheckReport = (filter: ChassisCheckReportFilterDef) => {
  return api.get(ReportingEndpointsEnum.CHASSIS_CHECK, {
    params: filter,
  });
};

const getChassisCheckReportExportData = (filter: ChassisCheckFilterDef) => {
  return api
    .get(ReportingEndpointsEnum.CHASSIS_CHECK_REPORT_EXPORT, {
      params: filter,
      responseType: "arraybuffer",
    })
    .then(response => response.data);
};

const getChassisCheckCategories = (filter: PaginationFilterDef) => {
  return api.get(ReportingEndpointsEnum.CHASSIS_CHECK_CATEGORIES, {
    params: filter,
  });
};

const getChassisCheckReportById = (id: ChassisCheckReportDef["id"]) => {
  return api.get(`${ReportingEndpointsEnum.CHASSIS_CHECK}/${id}`);
};
const getChassisCheckPdfById = (id: ChassisCheckReportDef["id"]) => {
  return api.get(`${ReportingEndpointsEnum.CHASSIS_CHECK}/${id}/file`, {
    responseType: "arraybuffer",
  });
};

const updateChassisCheckReport = (
  id: ChassisCheckReportDef["id"],
  fields: ChassisCheckReportRequestDef
) => {
  return api.patch(
    `${ReportingEndpointsEnum.CHASSIS_CHECK}/${id}?status=${
      fields.status ? fields.status : ""
    }&notes=${fields.notes ? fields.notes : ""}&fixall=${
      fields.fixall ? fields.fixall : false
    }`
  );
};

const getPositionReport = (filters?: any) => {
  return api.get(ReportingEndpointsEnum.REPORTING_STREAKS, {
    params: filters,
  });
};

const getAuditLogReport = (filter: AuditLogReportFilterDef) => {
  return api.get(ReportingEndpointsEnum.AUDITLOG, {
    params: filter,
  });
};

export const reportingApi = {
  getSessionReport,
  getSessionReportExportData,
  getMessageReport,
  getMessageReportExportData,
  getChassisCheckReport,
  getChassisCheckReportExportData,
  getChassisCheckCategories,
  getChassisCheckReportById,
  updateChassisCheckReport,
  getPositionReport,
  getAuditLogReport,
  getChassisCheckPdfById,
};
