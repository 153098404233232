import { memo, useEffect, useState } from "react";

import {
  Descriptions,
  Modal,
  Button,
  Tag,
  Empty,
  message as AlertMessage,
} from "antd";
import { useTranslation } from "react-i18next";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { ChassisRepairStatus } from "@app/constants/chassis.constants";
import { DateTimeFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";
import useShowModal from "@app/hooks/useShowModal";
import { useAppDispatch } from "@app/redux/store";

import { getChassisCheckReportById } from "../../../../redux/chassis-check-report.slice";
import {
  ChassisCheckReportDef,
  ChassisCheckReportRequestDef,
} from "../../../../types/reporting.types";
import Damages from "../Damages/Damages";
import NotesForm from "../NotesForm/NotesForm";
import styles from "./ChassisReportModal.module.scss";

interface ChassisReportModalProps {
  onClose: () => void;
  onUpdateChassisReport: (
    id: ChassisCheckReportDef["id"],
    fields: ChassisCheckReportRequestDef
  ) => void;
}

const ChassisReportModal = memo(
  ({ onClose, onUpdateChassisReport }: ChassisReportModalProps) => {
    const [loading, setLoading] = useState(false);
    const [
      chassisCheckReport,
      setChassisCheckReport,
    ] = useState<ChassisCheckReportDef>();
    const { t } = useTranslation();
    const { showModal, actionId } = useShowModal();

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (actionId) {
        const getChassisCheckReport = async () => {
          setLoading(true);
          const response = await dispatch(
            getChassisCheckReportById(parseInt(actionId, 10))
          );
          if (getChassisCheckReportById.fulfilled.match(response)) {
            // Call update chassis report to change the status from new to read
            if (response?.payload?.repairStatus === ChassisRepairStatus.NEW) {
              onUpdateChassisReport(parseInt(actionId, 10), {
                status: ChassisRepairStatus.READ,
              });
            }
            setChassisCheckReport(response?.payload);
            setLoading(false);
          }
          if (getChassisCheckReportById.rejected.match(response)) {
            setLoading(false);
            AlertMessage.error(t("default.errorMessage"));
          }
        };
        getChassisCheckReport();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionId]);

    const saveNotes = (formValue: ChassisCheckReportRequestDef) => {
      const notes = formValue.notes?.trim();
      if (notes && actionId) {
        onUpdateChassisReport(parseInt(actionId, 10), { notes });
      }
    };

    const handleClose = () => {
      setChassisCheckReport(undefined);
      onClose();
    };

    return (
      <Modal
        title={t("reportingChassisCheck.chassisCheckReportTitle")}
        visible={showModal}
        onCancel={handleClose}
        destroyOnClose
        centered
        footer={[
          <Button onClick={handleClose}>{t("default.cancelTitle")}</Button>,
        ]}
        width={720}
      >
        {loading && !chassisCheckReport && <LoadingSpinner />}
        {(chassisCheckReport && (
          <Descriptions
            column={2}
            className={styles.reportDescription}
            title={t("reportingChassisCheck.reportTitle")}
          >
            <Descriptions.Item
              label={t("reportingChassisCheck.labelVehicleNo")}
            >
              {chassisCheckReport?.vehicle_no}
            </Descriptions.Item>
            <Descriptions.Item label={t("reportingChassisCheck.labelDriverNo")}>
              {chassisCheckReport?.reported?.user_id}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("reportingChassisCheck.labelDriverName")}
            >
              {chassisCheckReport?.reported?.user_name}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("reportingChassisCheck.labelChassisNo")}
            >
              {chassisCheckReport?.chassis.chassis_no}
            </Descriptions.Item>
            {chassisCheckReport?.chassis.number_plate && (
              <Descriptions.Item
                label={t("reportingChassisCheck.labelChassisNumberPlate")}
              >
                {chassisCheckReport?.chassis.number_plate}
              </Descriptions.Item>
            )}
            {chassisCheckReport?.status && (
              <Descriptions.Item
                label={t("reportingChassisCheck.labelRepairStatus")}
              >
                <Tag color="processing"> {chassisCheckReport?.status}</Tag>
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={t("reportingChassisCheck.labelCheckPerformed")}
              span={2}
            >
              {chassisCheckReport?.reported?.time &&
                formattedDateTime(
                  chassisCheckReport?.reported?.time,
                  DateTimeFormats.TIME_DAY_MONTH_YEAR
                )}
            </Descriptions.Item>
            {chassisCheckReport?.address && (
              <Descriptions.Item
                label={t("reportingChassisCheck.labelAddress")}
                span={2}
              >
                {chassisCheckReport?.address}
              </Descriptions.Item>
            )}
            {chassisCheckReport && (
              <Descriptions.Item
                label={t("reportingChassisCheck.labelNotes")}
                span={2}
              >
                <NotesForm
                  notes={chassisCheckReport?.notes}
                  onSubmit={saveNotes}
                />
              </Descriptions.Item>
            )}
            {chassisCheckReport?.notes_last_changed?.user_name && (
              <Descriptions.Item
                label={t("reportingChassisCheck.labelNotesChangedBy")}
                span={2}
              >
                {chassisCheckReport?.notes_last_changed?.user_name}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              className={styles.damageContainer}
              label={t("reportingChassisCheck.labelDamages")}
              span={2}
            >
              <Damages damages={chassisCheckReport?.damages} />
            </Descriptions.Item>
          </Descriptions>
        )) ||
          (!loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)}
      </Modal>
    );
  }
);

export default ChassisReportModal;
